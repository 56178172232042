import React from 'react';
import { styled } from '@glitz/react';
import MailIcon from 'Shared/Icon/mail';
import { pixelsToUnit, iota, delta, minMediumMediaQuery, epsilon } from 'Shared/Style';

type PropType = {
  header: string;
  text: string;
  email: string;
};

export default (props: PropType) => (
  <ContactUsContent>
    <Header>{props.header}</Header>
    <Text>{props.text}</Text>
    <EmailWrapper>
      <MailIcon />
      <Email>{props.email}</Email>
    </EmailWrapper>
  </ContactUsContent>
);

const ContactUsContent = styled.section({
  width: '100%',
  height: '100%',
  marginTop: pixelsToUnit(50),
  maxWidth: '100%',
  padding: { xy: pixelsToUnit(17) },
  [minMediumMediaQuery]: {
    maxWidth: '50%',
    padding: { xy: pixelsToUnit(23) },
  },
  maxHeight: pixelsToUnit(168),
  backgroundColor: '#F1F1F1',
});

const Header = styled.span({
  display: 'block',
  fontSize: delta,
  marginBottom: pixelsToUnit(20),
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(2),
  [minMediumMediaQuery]: {
    letterSpacing: pixelsToUnit(2.4),
    fontSize: iota,
  },
});
const Text = styled.div({
  fontSize: epsilon,
  marginBottom: pixelsToUnit(15),
  [minMediumMediaQuery]: {
    fontSize: delta,
    marginBottom: pixelsToUnit(20),
  },

  fontWeight: 'bold',
});

const EmailWrapper = styled.div({
  display: 'flex',
});
const Email = styled.span({
  paddingLeft: pixelsToUnit(8),
});
