import React from 'react';
import { styled } from '@glitz/react';
import { EpiProperty } from '@avensia/scope-episerver';
import { minMediumMediaQuery, small, pixelsToUnit } from 'Shared/Style';
import { pseudo } from '@glitz/core';

type PropType = {
  items: Scope.Property<Scope.ContentArea>;
};

export default (props: PropType) => {
  const BlockHolder = (items: React.ReactNode | any) => {
    return (
      !!items && (
        <LinkBlockHolder {...items}>
          {items.children}
          {new Array(4).fill(0).map((elem, index) => (
            <Placeholder key={index} />
          ))}
        </LinkBlockHolder>
      )
    );
  };
  return <EpiProperty for={props.items} component={innerProps => BlockHolder(innerProps)} />;
};

const LinkBlockItem = {
  flexBasis: '33.33%',
  maxWidth: '33.33%',
  width: '100%',
  padding: {
    xy: pixelsToUnit(5),
  },
  [minMediumMediaQuery]: {
    flexBasis: '25%',
    maxWidth: '25%',
    padding: {
      x: pixelsToUnit(10),
      y: small,
    },
  },
};

const LinkBlockHolder = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  marginTop: small,
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
  },

  ...pseudo(':first-child > div', { ...LinkBlockItem }),
});

const Placeholder = styled.div({
  ...LinkBlockItem,
  margin: {
    xy: 0,
  },
  padding: {
    xy: 0,
  },
});
